import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { Flex, Box, Heading } from 'rebass'
import Link from '../components/base/Link'
import Hero from '../components/base/Hero'
import BackedByIag from '../components/base/BackedByIag'
import Card from '../components/base/Card'
import Trustpilot from '../components/base/Trustpilot'
import { modelSlugToRoute } from '../utils/links'

export default ({ data }) => (
  <>
    <HelmetDatoCms seo={data.datoCmsCategory.seoMetaTags} />
    <Hero fluid={data.datoCmsCategory.heroImage.fluid}>
      <Heading as="h1" variant="hero.heading">{data.datoCmsCategory.heading}</Heading>
      <Heading as="h2" variant="hero.subheading">{data.datoCmsCategory.subheading}</Heading>
    </Hero>
    <Box mt={[-120, -160, -200]} variant="fixedResponsive" sx={{ position: 'relative', zIndex: 2 }}>
      <Flex mb={5} justifyContent="center" flexDirection={['column', 'column', 'row']} flexWrap="wrap">
        {data.datoCmsCategory.products.map(card =>
          <Card
            key={card.slug}
            name={card.name}
            description={card.description}
            tags={card.tags}
          >
            <Flex mt="auto" mx={-1}>
              <Link
                to={modelSlugToRoute(card.model.apiKey, card.slug)}
                variant='button'
                sx={{ display: 'block', flex: 1, mx: 1 }}
              >
                More
              </Link>
              {card.quoteUrl && (
                <Link
                  href={card.quoteUrl}
                  transfer
                  variant='outline'
                  as="a"
                  sx={{ display: 'block', flex: 1, mx: 1 }}
                >
                  Get Quote
                </Link>
              )}
            </Flex>
          </Card>
        )}
      </Flex>
    </Box>
    <Box px={[3, 3, 3, 6]}>
      <Trustpilot
        data-template-id="539adbd6dec7e10e686debee"
        data-style-height="500px"
        data-style-width="100%"
        data-stars="4,5"
      />
    </Box>
    <BackedByIag />
  </>
)

export const query = graphql`
  query CategoryQuery($slug: String!) {
    datoCmsCategory(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      heading
      subheading
      products {
        id
        name
        slug
        quoteUrl
        description
        tags {
          id
          label
          icon {
            url
          }
        }
        model {
          apiKey
        }
      }
      heroImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
